$(function() {
    $('.cert-date').datepicker({
        todayHighlight: true,
        autoclose: true,
        clearBtn: true
    });

    $('#start_date').change(function() {

        var me = $(this);

        if ($('#expiration_date').length > 0) {
            var duration = $('#certification_expiration_duration').val(),
                date = moment(new Date(me.val()));

            date.add(parseInt(duration), 'days');
            $('#expiration_date').val(date.format('MM/DD/YYYY'));

        }
    });

    $('#certification_uac').change(function() {
        handleStartDateUAC();
        handleUserCertificationFormAction();
    });

    $('#user-certification-add').submit(function(e) {
        var certId = $('#certification_uac').val();
        if (certId == '' || certId == '0') {
            alert('Please select a certification.');
            e.preventDefault();
            return false;
        }
    });

    if ($('#certification_uac').length > 0) {
        handleStartDateUAC();
        handleUserCertificationFormAction();
    }

    function handleStartDateUAC() {
        var startDate = $('#certification_uac'),
            expirationDate = startDate.find('option:selected').attr('data-duration'),
            expires = startDate.find('option:selected').attr('data-expires');

        if (parseInt(expires) == 1) {
            $('#input_has_expiration_date').show();
            $('#input_no_expiration_date').hide();

            $('#certification_expiration_duration').val(expirationDate);
        } else {
            $('#input_has_expiration_date').hide();
            $('#input_no_expiration_date').show();
            $('#certification_expiration_duration').val(0);
        }
    }

    function handleUserCertificationFormAction() {
        var certSelect = $('#certification_uac'),
            certificationId = certSelect.val();

        if (certificationId == '') {
            certificationId = 0;
        }

        $('#user-certification-add').attr('action', '/admin/certifications/' + parseInt(certificationId) + '/users');
    }
});